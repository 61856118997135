import React from "react";
import theme from "theme";
import { Theme, Text, Link, Box, Image, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Home | Meadowbrook Golf Haven
			</title>
			<meta name={"description"} content={"Meadowbrook Golf Haven - Where Every Swing Tells a Story"} />
			<meta property={"og:title"} content={"Home | Meadowbrook Golf Haven"} />
			<meta property={"og:description"} content={"Meadowbrook Golf Haven - Where Every Swing Tells a Story"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/65377149e2dd3a002221646b/images/ball_1.jpg?v=2023-10-24T09:08:20.037Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/65377149e2dd3a002221646b/images/Screenshot%202023-10-24%20120647.png?v=2023-10-24T09:07:01.170Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/65377149e2dd3a002221646b/images/Screenshot%202023-10-24%20120647.png?v=2023-10-24T09:07:01.170Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/65377149e2dd3a002221646b/images/Screenshot%202023-10-24%20120647.png?v=2023-10-24T09:07:01.170Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/65377149e2dd3a002221646b/images/Screenshot%202023-10-24%20120647.png?v=2023-10-24T09:07:01.170Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/65377149e2dd3a002221646b/images/Screenshot%202023-10-24%20120647.png?v=2023-10-24T09:07:01.170Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/65377149e2dd3a002221646b/images/Screenshot%202023-10-24%20120647.png?v=2023-10-24T09:07:01.170Z"} />
			<meta name={"msapplication-TileColor"} content={"https://uploads.quarkly.io/65377149e2dd3a002221646b/images/Screenshot%202023-10-24%20120647.png?v=2023-10-24T09:07:01.170Z"} />
		</Helmet>
		<Components.Header />
		<Section padding="0px 0 0px 0" background="--color-primary" md-padding="96px 0 0px 0" sm-padding="72px 0 0px 0">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				flex-direction="column"
				justify-content="center"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 60px 0px"
				sm-margin="0px 0px 40px 0px"
				sm-padding="0px 0px 0px 0px"
			>
				<Text
					margin="0px 0px 24px 0px"
					color="--light"
					font="--headline1"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					md-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				>
					Meadowbrook Golf Haven
				</Text>
				<Text
					margin="0px 0px 40px 0px"
					color="#FFFFFF"
					font="--lead"
					lg-text-align="center"
					lg-width="80%"
				>
					Welcome to Meadowbrook Golf Haven, your serene escape to enjoy the timeless sport of golf.
				</Text>
				<Box
					display="flex"
					sm-flex-direction="column"
					sm-width="100%"
					sm-text-align="center"
					justify-content="flex-start"
					align-items="center"
				>
					<Link
						href="/contact-us"
						padding="12px 24px 12px 24px"
						color="--dark"
						background="--color-secondary"
						text-decoration-line="initial"
						font="--lead"
						border-radius="8px"
						margin="0px 16px 0px 0px"
						transition="background-color 0.2s ease-in-out 0s"
						hover-background="--color-orange"
						sm-margin="0px 0px 16px 0px"
						sm-text-align="center"
						sm-width="100%"
						hover-transition="background-color 0.2s ease-in-out 0s"
					>
						Get Started
					</Link>
					<Link
						href="/services"
						padding="10px 24px 10px 24px"
						color="--secondary"
						text-decoration-line="initial"
						font="--lead"
						border-radius="8px"
						margin="0px 16px 0px 0px"
						border-width="2px"
						border-style="solid"
						border-color="--color-secondary"
						sm-margin="0px 0px 0px 0px"
						sm-text-align="center"
						lg-margin="0px 0px 0px 0px"
						sm-width="100%"
						hover-border-color="--color-orange"
						hover-color="--orange"
						hover-transition="color 0.2s ease-in-out 0s,border 0.2s ease-in-out 0s"
						transition="color 0.2s ease-in-out 0s,border 0.2s ease-in-out 0s"
					>
						Our Services
					</Link>
				</Box>
			</Box>
			<Box
				display="flex"
				width="50%"
				justify-content="center"
				overflow-y="hidden"
				overflow-x="hidden"
				lg-width="100%"
			>
				<Image
					src="https://uploads.quarkly.io/65377149e2dd3a002221646b/images/Group%2011.png?v=2023-10-24T08:40:38.988Z"
					width="555px"
					max-width="100%"
					transform="translateY(10px)"
					transition="transform 0.5s ease-in-out 0s"
					hover-transform="translateY(0px)"
					sm-width="100%"
					srcSet="https://smartuploads.quarkly.io/65377149e2dd3a002221646b/images/Group%2011.png?v=2023-10-24T08%3A40%3A38.988Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/65377149e2dd3a002221646b/images/Group%2011.png?v=2023-10-24T08%3A40%3A38.988Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/65377149e2dd3a002221646b/images/Group%2011.png?v=2023-10-24T08%3A40%3A38.988Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/65377149e2dd3a002221646b/images/Group%2011.png?v=2023-10-24T08%3A40%3A38.988Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/65377149e2dd3a002221646b/images/Group%2011.png?v=2023-10-24T08%3A40%3A38.988Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/65377149e2dd3a002221646b/images/Group%2011.png?v=2023-10-24T08%3A40%3A38.988Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/65377149e2dd3a002221646b/images/Group%2011.png?v=2023-10-24T08%3A40%3A38.988Z&quality=85&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" background="--color-primary">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				align-items="center"
				flex-direction="column"
				justify-content="center"
				margin="0px 0px 33px 0px"
				width="100%"
				sm-margin="0px 0px 30px 0px"
				padding="0px 100px 0px 100px"
				lg-padding="0px 0px 0px 0px"
			>
				<Text
					margin="0px 0px 16px 0px"
					color="#ffffff"
					font="--headline1"
					text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				>
					Experience Golf at Its Best
				</Text>
				<Text
					margin="0px 0px 40px 0px"
					color="#FFFFFF"
					font="--lead"
					text-align="center"
					lg-width="80%"
				>
					Meadowbrook Golf Haven offers a tranquil golfing experience with pristine greens, unique signature holes, and dedicated training from experienced instructors. Find your golf escape in our serene settings.
				</Text>
			</Box>
			<Box
				display="grid"
				flex-wrap="wrap"
				width="100%"
				align-items="stretch"
				justify-content="center"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="32px"
				md-grid-template-columns="1fr"
				justify-items="center"
			>
				<Box
					width="100%"
					display="flex"
					padding="48px 40px 56px 40px"
					background="--color-light"
					border-radius="24px"
					align-items="center"
					justify-content="flex-start"
					flex-direction="column"
					lg-padding="48px 35px 56px 35px"
				>
					<Text margin="0px 0px 24px 0px" font="--headline3">
						Pristine Greens
					</Text>
					<Text margin="0px" color="--dark" text-align="center" font="--lead">
						Our meticulously maintained greens offer players a smooth and consistent playing surface, ensuring a quality game every time.
					</Text>
				</Box>
				<Box
					width="100%"
					display="flex"
					padding="48px 40px 56px 40px"
					background="--color-light"
					border-radius="24px"
					align-items="center"
					justify-content="flex-start"
					flex-direction="column"
					lg-padding="48px 35px 56px 35px"
					position="relative"
				>
					<Text margin="0px 0px 24px 0px" font="--headline3">
						Signature Holes
					</Text>
					<Text
						margin="0px 0px 48px 0px"
						color="--dark"
						text-align="center"
						font="--lead"
						flex="1 1 auto"
					>
						{" "}While we boast a traditional layout, a few of our holes offer a unique twist to challenge and delight every golfer, from the rookie to the seasoned pro.{"\n\t\t\t\t "}
					</Text>
				</Box>
				<Box
					width="100%"
					display="flex"
					padding="48px 40px 56px 40px"
					background="--color-light"
					border-radius="24px"
					align-items="center"
					justify-content="flex-start"
					flex-direction="column"
					lg-padding="48px 35px 56px 35px"
				>
					<Text margin="0px 0px 24px 0px" font="--headline3">
						Classic Clubhouse
					</Text>
					<Text
						margin="0px 0px 48px 0px"
						color="--dark"
						text-align="center"
						font="--lead"
						flex="1 1 auto"
					>
						After your game, relax and recount your best shots at our warm and welcoming clubhouse, serving refreshments and light bites.
					</Text>
				</Box>
			</Box>
		</Section>
		<Section text-align="center" color="--light" padding="80px 0" sm-padding="40px 0">
			<Text
				as="h1"
				font="--headline1"
				color="--primary"
				md-font="--headline2"
				margin="10px 0 0 0"
			>
				Facilities and Services
			</Text>
			<Text
				as="p"
				font="--lead"
				color="--dark"
				margin="40px 0 20px 0"
				text-align="left"
			>
				• Gear Up: Traveling light? We offer club rentals, ensuring you have the right equipment to enjoy your game to the fullest.
				<br />
				<br />
				• Meadowbrook Masters: Whether you're just beginning your golf journey or fine-tuning your skills, our experienced instructors are here to guide and support. We believe in nurturing every player's potential.
			</Text>
		</Section>
		<Section
			padding="90px 0 90px 0"
			sm-padding="40px 0"
			sm-min-height="auto"
			display="flex"
			quarkly-title="Advantages/Features-7"
		>
			<Override
				slot="SectionContent"
				flex-direction="column"
				sm-min-width="280px"
				lg-flex-wrap="wrap"
				display="flex"
			/>
			<Box
				display="flex"
				width="100%"
				flex-direction="column"
				justify-content="flex-start"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="flex-start"
				sm-margin="0px 0px 30px 0px"
				sm-padding="0px 0px 0px 0px"
				lg-flex-direction="row"
				lg-flex-wrap="wrap"
				sm-width="100%"
				md-margin="0px 0px 30px 0px"
				margin="0px 0px 64px 0px"
			>
				<Text
					as="h1"
					margin="0px 0px 24px 0px"
					font="--headline2"
					color="--primary"
					width="100%"
					md-width="100%"
					md-margin="0px 0px 32px 0px"
					md-padding="0px 0px 0px 0px"
					text-align="center"
					md-text-align="center"
					sm-text-align="left"
				>
					Why Choose Meadowbrook Golf Haven?
				</Text>
			</Box>
			<Box
				width="100%"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="48px"
				lg-grid-gap="48px"
				lg-width="100%"
				sm-grid-template-columns="1fr"
				lg-grid-template-columns="repeat(2, 1fr)"
			>
				<Box align-items="flex-start" display="flex" flex-direction="column" sm-align-items="flex-start">
					<Text font="--headline3" color="--dark" margin="0px 0px 6px 0px">
						Exceptional Course Maintenance
					</Text>
					<Text margin="8px 0px 0px 0px" font="--base" color="--dark" sm-text-align="left">
						Our commitment to top-tier maintenance ensures that every fairway, green, and bunker is kept in impeccable condition, promising a seamless play experience.
					</Text>
				</Box>
				<Box align-items="flex-start" display="flex" flex-direction="column" sm-align-items="flex-start">
					<Text font="--headline3" color="--dark" margin="0px 0px 6px 0px" sm-text-align="left">
						Accessible for All
					</Text>
					<Text margin="8px 0px 0px 0px" font="--base" color="--dark" sm-text-align="left">
						With multiple tee options and a balanced course layout, players of all skill levels can enjoy a challenging yet achievable game.
					</Text>
				</Box>
				<Box align-items="flex-start" display="flex" flex-direction="column" sm-align-items="flex-start">
					<Text font="--headline3" color="--dark" margin="0px 0px 6px 0px" sm-text-align="left">
						Dedicated Training Programs
					</Text>
					<Text margin="8px 0px 0px 0px" font="--base" color="--dark" sm-text-align="left">
						Our Meadowbrook Masters are not just instructors – they're passionate mentors. They're dedicated to nurturing every golfer's journey, from fundamentals to finesse.
					</Text>
				</Box>
				<Box align-items="flex-start" display="flex" flex-direction="column" sm-align-items="flex-start">
					<Text font="--headline3" color="--dark" margin="0px 0px 6px 0px" sm-text-align="left">
						Personalised Service
					</Text>
					<Text margin="8px 0px 0px 0px" font="--base" color="--dark" sm-text-align="left">
						Our team is attentive and focused on the details. From the moment you arrive, you'll experience unparalleled hospitality that makes your golf outing feel like a personal retreat.
					</Text>
				</Box>
				<Box align-items="flex-start" display="flex" flex-direction="column" sm-align-items="flex-start">
					<Text font="--headline3" color="--dark" margin="0px 0px 6px 0px" sm-text-align="left">
						Calm and Scenic Surroundings
					</Text>
					<Text margin="8px 0px 0px 0px" font="--base" color="--dark" sm-text-align="left">
						Nestled away from the hustle and bustle, our club offers a tranquil ambiance, allowing golfers to truly relax and immerse themselves in the game.
					</Text>
				</Box>
				<Box align-items="flex-start" display="flex" flex-direction="column" sm-align-items="flex-start">
					<Text font="--headline3" color="--dark" margin="0px 0px 6px 0px" sm-text-align="left">
						Affordable Membership Packages
					</Text>
					<Text margin="8px 0px 0px 0px" font="--base" color="--dark" sm-text-align="left">
						Quality shouldn't come at a premium. We offer a range of membership packages that provide exceptional value, ensuring everyone has the opportunity to enjoy Meadowbrook.
					</Text>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});